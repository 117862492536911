<template lang="pug">
main
  .container-fluid  
    .row
      .col-12.text-center

  //.container.mt-5
    .row
      .col-12.text-center
        h1.mt-5
          | Tipsy Granita.<br> Italian Ice with booze.<br/><br/>
          //| Stay updated <a href="https://www.instagram.com/tipsygranita/" target="_blank">@tipsygranita</a><br/><br/>
  
 
  
  .hero.p-5(style="height: 100px; width: 500px")
    .row
      .col-4.text-center
        h6
          b-link(to="mailto:hello@ciaone.fun").text-muted hello@ciaone.fun
      .col-4.text-center  
        h6
          b-link(to="/about").text-muted Imprint

        
  .container
   
    .text-center
      img(src="@/assets/lo1.png", style="max-width: 300px").img-fluid.mt-5
    
    .text-center.mt-5
      a(href="https://www.instagram.com/tipsygranita/" target="_blank", style="color: black")
        h4
          b-icon-instagram()
          |  Follow us on Instagram
      
   

    //.ticker
      .ticker-logo
        
     
      //.ticker-title
        span Breaking News
        span Breaking News
        span Breaking News
        span Breaking News
        span Breaking News
        span Breaking News
        span Breaking News
        span Breaking News
        span Breaking News
        span Breaking News
        span Breaking News
        span Breaking News
      .ticker-news
        span
          span Coming this Summer - 
          span Arriving this Summer - 
          span Dropping this Summer - 
          span Launching this Summer -  
         
          span Coming this Summer -  
          span Arriving this Summer - 
          span Dropping this Summer - 
          span Launching this Summer -  
          
          span Coming this Summer - 
          span Arriving this Summer - 
          span Dropping this Summer - 
          span Launching this Summer -  
         
          span Coming this Summer -  

    

  //.imprint  
    router-link(to="/about").small.mt-1.text-muted Imprint
   

  //section
    transition(name="fade")
    .container.bordered 
      h1 Choose your fighter
      
        img(src="@/assets/md@0.5x.png").md
        //<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/GM8p3QgD22U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  

.bordered {
  border: 1px solid black;
  border-radius: 4px;
  padding: 20px;
  margin: 20px

}

.mt-10 {
  margin-top: 200px;
}

img.shoot {
  border: 5px solid white;
}

.ticker {
  overflow: hidden;
  width: 100%;
  
  position: fixed;
  bottom: -5px;
  left: 0;
  font-family: 'Octopus', Arial;
}

.repeat {
 background: url("@/assets/lyel.png") repeat left top; 
}

.icon-info {
  display: block;
  padding: 0px 10px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  box-shadow: 0 0 2px 3px rgba(0,0,0,.5);
  position: absolute;
  top: -6px;
  left: -45px;
  background-color: #29a1e0;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.ticker-title {
  width: 2500px;
  margin-top: 5px;
  padding-bottom: 2px;
  color: white;
  font-size: 16px;
  
  font-family: "Octopus", Helvetica, Arial;
  background-color: #FE7400;
  //background: linear-gradient(135deg,  rgba(74,255,255,1) 0%,rgba(58,208,221,1) 11%,rgba(38,153,181,1) 24%,rgba(29,127,162,0.93) 30%,rgba(29,127,162,0.5) 68%); /* W3C */
}

.ticker-title > * {
  display: inline-block;
  margin-right: 50px;
  animation: title 6s infinite linear;
}

@keyframes title {
  0% {transform: translateX(0);}
  100% {transform: translateX(-233px);}
}


.ticker-news {
  width: 7000px;
  padding: 10px 0;
  color: #ffffff;
  font-size: 36px;
  color: black;
  background-color:  #FFF3E3;
  //background: linear-gradient(135deg,  rgba(74,255,255,1) 0%,rgba(29,127,162,1) 11%,rgba(29,127,162,0.5) 29%); /* W3C */
}

.ticker-news > * {
  display: inline-block;
  animation: news 30s infinite linear;
}

@keyframes news {
  0% {transform: translateX(0);}
  100% {transform: translateX(-4135px);}
}




.hero {
      height: 100%;
      bottom: 0;
      position: absolute;
    }
  section {
    padding: 20px;
    

    background-color: rgb(255, 243, 227);

    width: 100%;

  }
  main {
    margin-top: 100px;
  }

  .logo {
    display: block;
    width: 100px;
    position: fixed;
    top: 10px;
    left: 10px;

    margin: 0 auto;
   
  }


.logor {
    display: block;
   
    position: fixed;
    top: 200px;
    right: 50%;
    font-size: 3em;

    margin: 0 auto;
   
  }


  .logol {
    display: block;
    width: 400px;
    position: fixed;
    top: 20px;
    left: calc(50% - 200px);

    margin: 0 auto;
   
  }

  .follow {
    display: block;
    width: 500px;

    @media (max-width: 500px) {
       width: 300px;
    }

    padding: 20px;
    //background: rgba(255, 243, 227, 0.3);
    font-size: 25px;
      
    font-family: 'Bodoni Moda', serif;
 
    position: fixed;
    bottom: 80px;
    right: 20px;

    margin: 0 auto;
   
  }

   .imprint {
    display: block;
    
    position: fixed;
    font-size: 12px;
    bottom: 2px;
    left: 10px;
    a {
      text-decoration: none;
    }

    margin: 0 auto;
   
  }

  .moving {
 
      div{
        display:flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        width: 100%;
        margin: 20px auto;
        overflow: hidden;
      .news-message{
        display : flex;
        flex-shrink: 0;
        height: 50px;
        align-items: center;
        animation: slide-left 20s linear infinite;
        font-family: "Octopus";
        background: none;
        p{
          font-size: 2.5em;
          font-weight: 100;
          padding-left: 0.5em;
        }
        @keyframes slide-left {
        from {
          -webkit-transform: translateX(0);
                  transform: translateX(0);
        }
        to {
          -webkit-transform: translateX(-300%);
                  transform: translateX(-300%);
        }
      }
      }
      }
}


  .md {
   max-width: 300px;
  }

  h1 {
    color: black;
  }
</style>
