<template lang="pug">
main
  b-modal(ref="sad", size="md" centered hide-header hide-footer ok-variant="link" cancel-variant="link" ok-title="YES" cancel-title="NO" @cancel="$refs.sad.show()").x-modal
    .oct Ciaone
    | Unfortunately, you have not yet reached the minimum age of 18. The sale of alcohol to young people under the age of 18 is prohibited in Germany.
    <br><br>

  b-modal(ref="check", size="md" centered hide-header ok-variant="link" cancel-variant="link" ok-title="YES" cancel-title="NO" @cancel="$refs.sad.show()").x-modal
    h1 Ciaone
    p The products offered on this website contain alcohol.
    | ARE YOU OVER 18 YEARS OLD?
    <br><br>
    
  router-view()


</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  mounted() {
    //this.$refs.check.show()
  },
  components: {
    HelloWorld
  }
}
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:wght@400;500;600&display=swap');


@font-face {
  font-family: "Octopus";
  src: local("Octopus"),
   url(./assets/fonts/o.woff) format("opentype");
   
}


.oct {
    font-family: "Octopus", Helvetica, Arial !important;
}

a {
  text-decoration: none !important;
}
html, body {
  margin: 0;
  background-color: #FFF3E3;
  font-family: 'Bodoni Moda', serif;
 
}

.modal {
  text-align: center;
   color: white;
   background-color: rgba($color: #000000, $alpha: 0.8) !important;
   height: 90%;
   min-width: 90%;
  .modal-content {
    background-color: rgba($color: #000000, $alpha: 0.5) !important;
  }
}

.btn-link {
  text-decoration: none !important;
  color: #FFF3E3 !important;
  border: 1px solid #FFF3E3 !important;
}


#app {
  font-family: 'Bodoni Moda', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
