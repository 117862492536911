<template lang="pug">
main
  
  
  section
  //video(autoplay muted loop id="headvideo2"  playsinline)
    source(src="@/assets/nb.mp4" type="video/mp4")
  //.cover
  //.mp
  //video(autoplay muted loop id="headvideo"  playsinline)
    source(src="@/assets/org.mp4" type="video/mp4")
  
  HelloWorld()

  //div.d-flex.justify-content-center
    div.col-md-6 KK

 

  



</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style lang="scss">


@font-face {
  font-family: "Octopus";
  src: local("Octopus"),
   url(@/assets/fonts/o.woff) format("opentype");
   
}

h1 {
  font-size: 3em;
}
h1, h2, h3, h4, h5, h6 {
     font-family: 'Bodoni Moda', serif;
}

.oct {
  font-family: "Octopus", Helvetica, Arial;
}



html, body {
  margin: 0;
  background-color: #FFF3E3;
 
 
}


.mp2 {
  top:20px;
  width: 50%;
  height: 50%;
  position: fixed;

  transform: rotateY(0deg) rotate(-30deg);
  overflow: hidden;
  
}


.cover {
  width: 100%;
  min-height: 800px;
  background: url("@/assets/bck.jpg") no-repeat center center;
  background-size: cover;
}

.mp {
  top:0;
  width: 100%;
  height: 100%;
  position: fixed;
  opacity: 0.7;
  background: url("@/assets/lyel.png") repeat left top; 
  //transform: rotateY(0deg) rotate(-30deg);
  overflow: hidden;
  
}
#headvideo {

  top: 25%;
  z-index: 100;
  display: block;
  width: 40%;
  position: fixed;
  left: calc(30vh);
  transform: rotateY(0deg) rotate(-30deg);
  background-color: #FFF3E3;
  -webkit-mask-image: -webkit-gradient(linear, left top, 
  left bottom, from(rgba(0,0,0,1)), to(rgb(255, 243, 227, 0.0)));
}

#headvideo2 {

  bottom: 0;
  right: 0;
  z-index: -1;
  display: block;
  width: 100%;

  background-color: #FFF3E3;
  -webkit-mask-image: -webkit-gradient(linear, left top, 
  left bottom, from(rgba(0,0,0,0.9)), to(rgb(255, 243, 227, 0.1)));
}

#app {
  font-family: 'Bodoni Moda', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
